// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";








#loading {
  background: rgba(0, 0, 0, 0.48);
  padding-top: 225px;
  margin-bottom: 20px;
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1000;
  display: none;
}

.form-horizontal .control-label {
  text-align: left;
}
.loading-icn {
  background-color: #ffffff;
  background-image: url("/assets/img/loading.gif");
  background-size: 25px 25px;
  background-position:right center;
  background-repeat: no-repeat;
}
.delCartItem {
  cursor: pointer;
}

HEADER {
  background: #505557;
  border-bottom: solid 4px #98ceea;
  padding-top: 30px;
  padding-bottom: 30px;
  color: #FFF;
  font-size: 24px;
}



HEADER {

}

FOOTER {
  margin-top: 20px;
  margin-bottom: 20px;
}

#doc_box {
  background: #98ceea;

}

.inside {
  padding: 30px;
  padding-top: 20px;
  padding-bottom: 0px;
}

H1 {
  margin:0;
  padding-bottom: 0;
  font-size: 27px;
}

H2 {
  font-size: 25px;
}

.searchresults {
  line-height: 25px;
}



#doc_box .linking {
  display: block;
  padding: 20px;
  padding-left: 30px;
  background: #44a5d7;
  color: #043569;
  font-weight: normal;
  font-size: 20px;
}

.sbox {
  background: #98ceea;
  display: block;
  padding: 10px;
  text-align: center;
}
.big {
  font-size: 25px;
}

@media (max-width: 768px) {
  .bill {
    width: 100% !important;
  }
}

#footer {
  a {
    color: #58595b;
  }
}

#sitemap {
  background: #505557;
  color: rgba(255, 255, 255, 0.5);
}

#sitemap B {
  color: #FFF;
}

#sitemap A {
  color: rgba(255, 255, 255, 0.7);
}



#content {
  padding-top: 50px;
  padding-bottom: 50px;
  min-height: 600px;
}









#content-area {
  background: #FFF;
  padding: 0;
  padding: 40px 0;
}


@media only screen and (min-width : 768px){
  .cart-width {
    width: 400px;
  }

}

@media (min-width: 768px){
  #search-inputs {
    width: 260px !important;
  }

  #content-area {
    min-height: 650px;
  }
}

@media (max-width: 768px){
  #proofbutton {
    display: block !important;
    margin: 0 !important;
  }


  #footer div {
    text-align: left !important;
  }
}



.tooltip-link {
  color: #043569;
}













$lightblue: #f7fbff;
$fontgrey: #58595b;
$fontlightgrey: #8095a2;
$fontdarkgrey: #6c838e;
$lightgrey: rgba(0,0,0,0.1);



::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $fontgrey;
}
::-moz-placeholder { /* Firefox 19+ */
  color: $fontgrey;
}
:-ms-input-placeholder { /* IE 10+ */
  color: $fontgrey;
}
:-moz-placeholder { /* Firefox 18- */
  color: $fontgrey;
}

:placeholder-shown {
  color: $fontgrey;
}

::placeholder {
  color: $fontgrey;
}


BODY {
  color: $fontgrey;
  background-color: $lightblue;
  font-family: Lato, sans-serif;
  font-weight: 300;
}

.lightborder {
   border: solid 1px $lightgrey;
 }

.lightborder-right {
  border-right: solid 1px $lightgrey;
}

.lightborder-left {
  border-left: solid 1px $lightgrey;
}

#footer {
  margin-bottom:0;
  background-color: $lightblue;
  color: $fontlightgrey;
}

HR {
  box-shadow: inset 1px 10px 10px -11px rgba(0,0,0,0.3);
}
.no-margin {
  margin: 0;
}

H2 {
  font-weight: 300;
  font-size: 20px;
}

.p-20 {
  padding: 20px;
}

.m-t-20 {
  margin-top: 20px;
}


H5 {
  font-size: 16px;
  font-weight: 400;
}

H1 {
  font-weight: 300;
}


.content-light {
  box-shadow: inset 1px 10px 5px -11px rgba(0,0,0,0.2);
  border-top: solid 1px rgba(0,0,0,0.2);
  background-color: $lightblue;
  margin-bottom:0;
  padding: 20px;
}

.content-white {
  box-shadow: inset 1px 10px 5px -11px rgba(0,0,0,0.2);
  border-top: solid 1px rgba(0,0,0,0.2);
  background-color: #FFF;
  margin-bottom:0;
  padding: 20px;
}


.jumbotron {
  box-shadow: inset 1px 10px 5px -11px rgba(0,0,0,0.2);
  border-top: solid 1px rgba(0,0,0,0.2);
}

#search-input {
  border-radius: 50px;
  text-align: center;
}

.list-group-item {
  border: none;
  background: #fcf0a1;
  border-bottom: solid 5px #daac60;
  color: $fontdarkgrey !important;
}


.breadcrumb {
  margin: 0; padding: 0;
}

.img-responsive {
  margin: 0 auto;
}

H1, H2, H3, H4, H5, H6 {
  text-justify: inter-word;
}

.dropdown-menu > li > a {
  font-weight: 300;
}